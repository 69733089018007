import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.function.name.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var myFooter = function myFooter() {
  return import("./common/myFooter");
};

var comment = function comment() {
  return import("./comment/comment");
};

var process = function process() {
  return import("./common/process");
};

var commentBox = function commentBox() {
  return import("./comment/commentBox");
};

var proButton = function proButton() {
  return import("./common/proButton");
};

import MarkdownIt from 'markdown-it';
export default {
  components: {
    myFooter: myFooter,
    comment: comment,
    commentBox: commentBox,
    proButton: proButton,
    process: process
  },
  data: function data() {
    return {
      id: this.$route.query.id,
      subscribe: false,
      article: {},
      articleContentHtml: "",
      treeHoleList: [],
      weiYanDialogVisible: false,
      newsTime: "",
      showPasswordDialog: false,
      password: "",
      tips: ""
    };
  },
  created: function created() {
    if (!this.$common.isEmpty(this.id)) {
      this.getArticle(localStorage.getItem("article_password_" + this.id));

      if ("0" !== localStorage.getItem("showSubscribe")) {
        this.$notify({
          title: '文章订阅',
          type: 'success',
          message: '点击文章下方小手 - 订阅/取消订阅专栏（标签）',
          duration: 0,
          onClose: function onClose() {
            return localStorage.setItem("showSubscribe", "0");
          }
        });
      }
    }
  },
  mounted: function mounted() {// window.addEventListener("scroll", this.onScrollPage);
  },
  methods: {
    subscribeLabel: function subscribeLabel() {
      var _this = this;

      if (this.$common.isEmpty(this.$store.state.currentUser)) {
        this.$message({
          message: "请先登录！",
          type: "error"
        });
        return;
      }

      this.$confirm('确认' + (this.subscribe ? '取消订阅' : '订阅') + '专栏【' + this.article.label.labelName + '】？' + (this.subscribe ? "" : "订阅专栏后，该专栏发布新文章将通过邮件通知订阅用户。"), this.subscribe ? "取消订阅" : "文章订阅", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(function () {
        _this.$http.get(_this.$constant.baseURL + "/user/subscribe", {
          labelId: _this.article.labelId,
          flag: !_this.subscribe
        }).then(function (res) {
          if (!_this.$common.isEmpty(res.data)) {
            _this.$store.commit("loadCurrentUser", res.data);
          }

          _this.subscribe = !_this.subscribe;
        }).catch(function (error) {
          _this.$message({
            message: error.message,
            type: "error"
          });
        });
      }).catch(function () {
        _this.$message({
          type: 'success',
          message: '已取消!'
        });
      });
    },
    submitPassword: function submitPassword() {
      if (this.$common.isEmpty(this.password)) {
        this.$message({
          message: "请先输入密码！",
          type: "error"
        });
        return;
      }

      this.getArticle(this.password);
    },
    deleteTreeHole: function deleteTreeHole(id) {
      var _this2 = this;

      if (this.$common.isEmpty(this.$store.state.currentUser)) {
        this.$message({
          message: "请先登录！",
          type: "error"
        });
        return;
      }

      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(function () {
        _this2.$http.get(_this2.$constant.baseURL + "/weiYan/deleteWeiYan", {
          id: id
        }).then(function (res) {
          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this2.getNews();
        }).catch(function (error) {
          _this2.$message({
            message: error.message,
            type: "error"
          });
        });
      }).catch(function () {
        _this2.$message({
          type: 'success',
          message: '已取消删除!'
        });
      });
    },
    submitWeiYan: function submitWeiYan(content) {
      var _this3 = this;

      var weiYan = {
        content: content,
        createTime: this.newsTime,
        source: this.article.id
      };
      this.$http.post(this.$constant.baseURL + "/weiYan/saveNews", weiYan).then(function (res) {
        _this3.weiYanDialogVisible = false;
        _this3.newsTime = "";

        _this3.getNews();
      }).catch(function (error) {
        _this3.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    getNews: function getNews() {
      var _this4 = this;

      this.$http.post(this.$constant.baseURL + "/weiYan/listNews", {
        current: 1,
        size: 9999,
        source: this.article.id
      }).then(function (res) {
        if (!_this4.$common.isEmpty(res.data)) {
          res.data.records.forEach(function (c) {
            c.content = c.content.replace(/\n{2,}/g, '<div style="height: 12px"></div>');
            c.content = c.content.replace(/\n/g, '<br/>');
            c.content = _this4.$common.faceReg(c.content);
            c.content = _this4.$common.pictureReg(c.content);
          });
          _this4.treeHoleList = res.data.records;
        }
      }).catch(function (error) {
        _this4.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    onScrollPage: function onScrollPage() {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop < window.innerHeight / 4) {
        $(".toc").css("top", window.innerHeight / 2);
        $(".toc").css("display", "unset");
      } else if (scrollTop > window.innerHeight / 4 && scrollTop < $("#article-like").offset().top - window.innerHeight) {
        $(".toc").css("top", "100px");
        $(".toc").css("display", "unset");
      } else {
        $(".toc").css("display", "none");
      }
    },
    getTocbot: function getTocbot() {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = this.$constant.tocbot;
      document.getElementsByTagName('head')[0].appendChild(script); // 引入成功

      script.onload = function () {
        tocbot.init({
          tocSelector: '#toc',
          contentSelector: '.entry-content',
          headingSelector: 'h1, h2, h3, h4, h5, h6',
          scrollSmooth: true,
          fixedSidebarOffset: 'auto',
          scrollSmoothOffset: -100,
          hasInnerContainers: false
        });
      };
    },
    addId: function addId() {
      var headings = $(".entry-content").find("h1, h2, h3, h4, h5, h6");
      headings.attr('id', function (i, id) {
        return id || 'toc-' + i;
      });
    },
    getArticle: function getArticle(password) {
      var _this5 = this;

      this.$http.get(this.$constant.baseURL + "/article/getArticleById", {
        id: this.id,
        password: password
      }).then(function (res) {
        if (!_this5.$common.isEmpty(res.data)) {
          _this5.article = res.data;

          _this5.getNews();

          var md = new MarkdownIt({
            breaks: true
          });
          _this5.articleContentHtml = md.render(_this5.article.articleContent);

          _this5.$nextTick(function () {
            _this5.highlight();

            _this5.addId(); // todo 只有程序相关文章才显示toc
            // this.getTocbot();

          });

          if (!_this5.$common.isEmpty(password)) {
            localStorage.setItem("article_password_" + _this5.id, password);
          }

          _this5.showPasswordDialog = false;

          if (!_this5.$common.isEmpty(_this5.$store.state.currentUser) && !_this5.$common.isEmpty(_this5.$store.state.currentUser.subscribe)) {
            _this5.subscribe = JSON.parse(_this5.$store.state.currentUser.subscribe).includes(_this5.article.labelId);
          }
        }
      }).catch(function (error) {
        if ("密码错误" === error.message.substr(0, 4)) {
          if (!_this5.$common.isEmpty(password)) {
            _this5.$message({
              message: "密码错误，请重新输入！",
              type: "error"
            });
          }

          _this5.tips = error.message.substr(4);
          _this5.showPasswordDialog = true;
        } else {
          _this5.$message({
            message: error.message,
            type: "error"
          });
        }
      });
    },
    highlight: function highlight() {
      var attributes = {
        autocomplete: "off",
        autocorrect: "off",
        autocapitalize: "off",
        spellcheck: "false",
        contenteditable: "false"
      };
      $("pre").each(function (i, item) {
        var preCode = $(item).children("code");
        var classNameStr = preCode[0].className;
        var classNameArr = classNameStr.split(" ");
        var lang = "";
        classNameArr.some(function (className) {
          if (className.indexOf("language-") > -1) {
            lang = className.substring(className.indexOf("-") + 1, className.length);
            return true;
          }
        }); // 检测语言是否存在，不存在则自动检测

        var language = hljs.getLanguage(lang.toLowerCase());

        if (language === undefined) {
          // 启用自动检测
          var autoLanguage = hljs.highlightAuto(preCode.text());
          preCode.removeClass("language-" + lang);
          lang = autoLanguage.language;

          if (lang === undefined) {
            lang = "java";
          }

          preCode.addClass("language-" + lang);
        } else {
          lang = language.name;
        }

        $(item).addClass("highlight-wrap");
        $(item).attr(attributes);
        preCode.attr("data-rel", lang.toUpperCase()).addClass(lang.toLowerCase()); // 启用代码高亮

        hljs.highlightBlock(preCode[0]); // 启用代码行号

        hljs.lineNumbersBlock(preCode[0]);
      });
      $("pre code").each(function (i, block) {
        $(block).attr({
          id: "hljs-" + i
        });
        $(block).after('<a class="copy-code" href="javascript:" data-clipboard-target="#hljs-' + i + '"><i class="fa fa-clipboard" aria-hidden="true"></i></a>');
        new ClipboardJS(".copy-code");
      });

      if ($(".entry-content").children("table").length > 0) {
        $(".entry-content").children("table").wrap("<div class='table-wrapper'></div>");
      }
    }
  }
};